import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './Contact.module.css'
import HeroLayout from '../components/heroLayout'
import Layout from '../components/layout'
import BioPreview from '../components/bio'

class Contact extends React.Component {
    render() {
      const siteTitle = get(this, 'props.data.site.siteMetadata.title')      
      const [layoutHeroImage] = get(this, 'props.data.allContentfulLayoutHeroImage.edges')
      const team = get(this, 'props.data.allContentfulPerson.edges')

      return (
        <Layout location={this.props.location}>
          <div style={{ background: '#fff' }}>
            <Helmet title={siteTitle} />
            <HeroLayout data={layoutHeroImage.node} />
            <div className="wrapper">
              <h2 className="section-headline">Contact</h2>
              <figure class="quote">
              <blockquote>                
                <strong>Email</strong>: <a href="mailto:truth2kids@gmail.com">truth2kids@gmail.com</a><br/>
                <strong>Project Leader</strong>: 076 7464 581<br/>
                <strong>Project coordinator</strong>: 083 655 0111<br/>
              </blockquote>              
              </figure>
              <br/>
              <ul className="article-list">
                {team.map(({ node }) => {
                  return (
                    <li key={node.name}>
                      <BioPreview bio={node} />
                    </li>
                  )
                })}
              </ul>                              
            </div>
          </div>
        </Layout>
      )
    }
  }
  
  export default Contact

  export const contactQuery = graphql`
  query ContactQuery {
    allContentfulLayoutHeroImage(filter: {title: {regex: "/Extra 4/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
    allContentfulPerson(sort: {fields: name, order: ASC}) {
      edges {
        node {
          name                  
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }  
`